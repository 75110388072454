import * as React from 'react'
import Layout from '../../components/layout'
import '../../styles/style.scss'

import spaceHeating_logo from '../../images/Prioritization/spaceHeatingLogo.svg';
import lighting_logo from '../../images/Prioritization/LightingLogo.svg';
import renewables_logo from '../../images/Prioritization/RenewablesStorageLogo.svg';
import energyManagement_logo from '../../images/Prioritization/EnergyManagementLogo.svg';
import electrification_logo from '../../images/Prioritization/ElectrificationLogo.svg';
import ev_logo from '../../images/Prioritization/EVLogo.svg';


const styles = {

  row: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-evenly",
    margin: "10px 0px",
    flexWrap: 'wrap',
    maxWidth: "95%"
  },
  greenCircleCon:{
    flex: "0 0 30%",
    // margin: "0 auto 30px",
    display: "flex",
    justifyContent: "center",
  },
  greenCircle: {
    backgroundColor: "rgb(82, 151, 54)",
    borderRadius: "100%",
    height: "165px",
    width: "165px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: 'center',
    flexDirection: "column",
    marginBottom: "10px"
  },
  spacer:{
    marginBottom: "20px",
    width: "40%"
  },
  pTag:{
    color: "#fff",
    maxWidth: '155px',
    fontSize: '15px'
  }
}

const Prioritization = () => {
  return (
    <Layout pageTitle='Prioritization' nextBottomSlideBtn="How We Prioritize" nextBottomSlideBtnLink="/Prioritization/how-we-prioritize" id="prioritization">
      <p>Southern 360 helps you prioritize projects that optimize your energy use and advance sustainability and resiliency goals. We leverage your data to identify the highest-impact opportunities to enable you to move forward confidently with implementing your carbon reduction strategy</p>
      <div style={ styles.row }>
        <div style={ {...styles.greenCircleCon, ...{ animation: "fadeIn .75s ease-in-out forwards", opacity: 0  } } }>
          <div style={styles.greenCircle}>
            <img src={spaceHeating_logo} style={styles.spacer} alt="Space Heating and Cooling Logo"/>
            <p style={styles.pTag}>Space Heating and Cooling</p>
          </div>
        </div>
        <div style={ {...styles.greenCircleCon, ...{ animation: "fadeIn .75s ease-in-out 1s forwards", opacity: 0  } } }>
          <div style={styles.greenCircle} >
            <img src={lighting_logo} style={styles.spacer}alt="Lighting Logo"/>
            <p style={styles.pTag}>Lighting</p>
          </div>
        </div>
        <div style={ {...styles.greenCircleCon, ...{ animation: "fadeIn .75s ease-in-out 2s forwards", opacity: 0  } } }>
          <div style={styles.greenCircle} >
            <img src={renewables_logo} style={styles.spacer} alt="Renewables and Storage Logo"/>
            <p style={styles.pTag}>Renewables and Storage</p>
          </div>
        </div>
        <div style={ {...styles.greenCircleCon, ...{ animation: "fadeIn .75s ease-in-out 3s forwards", opacity: 0  } } }>
          <div style={styles.greenCircle} >
            <img src={energyManagement_logo} style={styles.spacer} alt="Energy Management Systems Logo"/>
            <p style={styles.pTag}>Energy Management Systems</p>
          </div>
        </div>
        <div style={ {...styles.greenCircleCon, ...{ animation: "fadeIn .75s ease-in-out 4s forwards", opacity: 0  } } }>
          <div style={styles.greenCircle} >
            <img src={electrification_logo} style={styles.spacer} alt="Electrification Solutions Logo"/>
            <p style={styles.pTag}>Electrification Solutions </p>
          </div>
        </div>
        <div style={ {...styles.greenCircleCon, ...{ animation: "fadeIn .75s ease-in-out 5s forwards", opacity: 0  } } }>
          <div style={styles.greenCircle} >
            <img src={ev_logo} style={styles.spacer} alt="Electric Vehicle Fleets and Infrastructure Logo"/>
            <p style={styles.pTag}>Electric Vehicle Fleets & Infrastructure</p>
          </div>
        </div>

      </div>
    </Layout>
  )
}

export default Prioritization